<template>
  <div>
    <!-- 登录后 -->
    <div class="BannerBox" v-if="centerNavList">
        
        <CenterNav
          :centerNavList="centerNavList"
        ></CenterNav>
        <el-main>
        <el-container  class="bgBox">
          <el-aside width="210px">
            <!-- <LeftNav/> -->
          </el-aside>
        </el-container>
    </el-main>
    </div>
    <!-- <el-main>
      <el-container  class="bgBox">
        <el-aside width="210px">
          <LeftNav/>
        </el-aside>
        <el-main>
          <el-row class="navContent" v-if="centerNavList">
            <CenterNav
              class="centerNav"
              :centerNavList="centerNavList"
            ></CenterNav>
            <RightNav
              class="rightNav"
              :articlesList="articlesList"
              :name="name"
              :memberLevelName="memberLevelName"
            >
            </RightNav>
          </el-row>
        </el-main>
      </el-container>
    </el-main> -->
  <div  class="home">
    <el-main >


      <el-row :gutter="20">
        <NewCombo></NewCombo>
      </el-row>
      <!-- 限时拼团 -->
      <el-row :gutter="20"  class="bgBox">
        <el-col :span="24">
          <FlashGroup></FlashGroup>
        </el-col>
      </el-row>

      <!-- 活动专区 -->
      <el-row v-if="centerNavList.activityDetail && centerNavList.activityDetail.length>0">
          <el-col :span="24">
            <div class="ActivityAreaBox">
              <div class="HeaderTit">
                <h3>活动专区</h3>
                <!-- <span class="subhead1">
                  省钱秘笈拼团购
                </span> -->
              </div>
              <el-col :sapn="24" class="bottom">
                <div v-for="(item, index) in centerNavList.activityDetail" :key="index" >
                  <img v-if='item'  :src="item.pic" alt="图片"  @click="jumpUrl(item.url,item.name)"/>
                </div>
              </el-col>
            </div>
          </el-col>
        </el-row>



      <el-row :gutter="20">
        <el-col :span="24">
          <Combo :productType="AconnectB"></Combo>
        </el-col>
        <!-- <el-col :span="12">
          <Combo :productType="setMeal"></Combo>
        </el-col> -->
      </el-row>
      <!-- v-if="hasValue" -->
      <el-row :gutter="20">
        <el-col :span="24">
          <ActivityModule :productType="TimeBuy"></ActivityModule>
        </el-col>
      </el-row>
       <!-- 人气爆品 -->
      <el-row :gutter="20">
        <el-col :span="24">
          <ActivityModule :productType="OpenBooking"></ActivityModule>
        </el-col>
      </el-row>

      <!-- 小额包邮 -->
      <el-row :gutter="20">
        <el-col :span="24">
          <comboLi productType="FreeMailGoods"></comboLi>
        </el-col>
      </el-row>
      <NewGoods class="newGoods"></NewGoods>
      <ShopArea :productType="HealthType"></ShopArea>
      <ShopArea :productType="OtcRecipe"></ShopArea>
      <!-- <AOG></AOG> -->
      <Zone class="zone" :hotRecommend="hotRecommend"></Zone>
      <BrandUnion></BrandUnion>


      <!-- 资质即将过期弹窗 -->
      <el-dialog class="AlertMsgBox" :visible.sync="AlertMsgBoxVisible" width="30%" :showClose="false">
        <div class="AlertMsgBgBox">
          <div class="imgBox">
            <img  width="100%" src="@/assets/AlertMsgBg.png" alt=""/>
          </div>
          <div class="MsgBox">
            <p>请及时联系专属客服更新 否则会影响您的购物体验</p>
            <ul>
              <li  v-for="(item, index) in MsgList" :key="index">
                {{item.titleName}} --- {{item.day}}天
              </li>
            </ul>
             <div class="clearBox"></div>
          </div>
          <div class="closeBtn" @click="CloseFun()">
              <img width="100%" src="@/assets/IKnow.png" alt=""/>
          </div>
        </div>
      </el-dialog>

      <el-dialog class="AlertBox" :visible.sync="dialogVisible" width="20%" >
        <div class="CouponsBox" v-if="popUps.advTarget == 4 && popUps.memberStoreReceiveCouponVO">
         <img  width="100%" :src="popUps.img" alt=""/>
         <ul v-if="popUps.memberStoreReceiveCouponVO.length>0">
            <li   v-for="(item, index) in popUps.memberStoreReceiveCouponVO" :key="index" @click="ReceiveCouponsFun(popUps.memberStoreReceiveCouponVO)">
              <div class="BgBox">
                <div  class="LeftBox">
                    <p v-if="item.promotionMethod==0"> 
                      <sup>￥</sup>{{ item.promotionValue }}
                    </p>
                    <p v-if="item.promotionMethod==1"> 
                      {{ item.promotionValue }}<sub>折</sub>
                    </p>
                    
                </div>
                <div  class="RightBtn">
                     <p>
                      <!-- {{Number(item.couponType)==0?'全场通用券':Number(item.couponType)==1?'单品券':Number(item.couponType)==2?'多品券':Number(item.couponType)==4?'未购券':'品类券'}}  -->
                      {{item.couponName}}
                    </p>
                    <div class="desMsg">
                      <!-- 可用 {{ item.canReceiveNum }}张 -->
                        <!-- 无门槛 --><span class="numMsg"  v-if="item.useCondition==0">无门槛优惠券</span>
                        <!-- 满减 --><span class="numMsg"  v-if="item.useCondition==1">满{{ item.useAmountCondition }}元可用</span>
                        <!-- 数量 --><span class="numMsg"  v-if="item.useCondition==2">
                                          <span v-if="item.couponType==4">满{{ item.useNumCondition }}个未购商品可用</span>
                                          <span v-else>满{{ item.useNumCondition }}个品种可用</span> 
                                    </span>
                    </div>
                </div>
              </div>
            </li>
          </ul>
          <div v-if="popUps.memberStoreReceiveCouponVO.length<3" class="GetBtn" @click="ReceiveCouponsFun(popUps.memberStoreReceiveCouponVO)">
              <img width="100%" src="@/assets/GetHomeBtn.png" alt=""/>
          </div>
        </div>
        <img v-else width="100%" :src="popUps.img" alt="" @click="linkTo(popUps)" />
      </el-dialog>
    </el-main>
  </div>
  </div>
</template>

<script>
import { MemberInfo,getMemberStoreCertification } from "api/member.js";
import { frontList ,getHomeBanners,getActivityOperation} from "api/home.js";

import { ReceiveCoupons } from "api/carToOrder.js";
const Zone = () => import("components/index/CommodityZone.vue");
const Combo = () => import("components/index/combo.vue");
const comboLi = () => import("components/index/comboLi.vue");

// 限时拼团

const FlashGroup = () => import("components/index/FlashGroup.vue");
const ActivityModule = () => import("components/index/ActivityModule.vue");
const ShopArea = () => import("components/index/shopArea.vue");
const NewGoods = () => import("components/index/NewGoods.vue");
// const AOG = ()=>import("components/index/AOG.vue");
const BrandUnion = () => import("components/index/BrandUnion.vue");
// const LeftNav = () => import("components/index/leftNav.vue");
const CenterNav = () => import("components/index/CenterNav.vue");
// const RightNav = () => import("components/index/RightNav.vue");
// 套餐
const NewCombo = () => import("components/index/NewCombo.vue");
import { PictureSpecs } from "api/goods.js";
import { mapGetters, mapMutations } from "vuex";
export default {
  name: "Home",
  components: {
    NewCombo,//新改套餐
    Zone,
    // LeftNav,
    ShopArea,
    Combo,
    comboLi,
    FlashGroup, //限时拼团
    ActivityModule,
    NewGoods,
    // AOG,    //即将到货  唯品特卖
    BrandUnion,
    CenterNav,
    // RightNav,
  },
  data() {
    return {
      nums: 3,
      setMeal: "setMeal",
      AconnectB: "AconnectB",
      TimeBuy: "TimeBuy",
      OpenBooking: "OpenBooking",
      HealthType: "Health_type", //当前类型医保用药
      OtcRecipe: "OTC", //当前类型OTC用药
      hasValue: false,
      articlesList: [], //公告文章列表
      popUps: "", //首页弹窗
      dialogVisible: false, //初始弹框不出现
      name: "",
      memberLevelName: "",
      hotRecommend: {},
      centerNavList: {},
      AlertMsgBoxVisible:false,  //资质即将过期弹窗
      MsgList:[],
    };
  },
  computed: {
    ...mapGetters(["memberNameFun"]),
  },
  //监听执行
  watch: {
    //  "memberNameFun": function (newValue) {
    //     if(newValue) {
    //      this.frontListFun()
    //     }
    // },
  },
  methods: {

    jumpUrl(urlType,name) {
      const query = urlType.split("?")[1].split("=")[1]
      let tagId = query;
       this.$router.push({name:"MarketingList",query:{tagId:tagId,name:name}})
    },
    ...mapMutations({
      memberName: "memberName",
    }),
    ReceiveCouponsFun(couponArr){
        let datas=[];
        if(couponArr.length>0){
        couponArr.forEach(item=> {
          datas.push(item.id)
        })
          // 领取优惠券 9999999999
          ReceiveCoupons(datas).then(res=>{
            if(res.data.code===200){
                this.dialogVisible = false;
                this.$message({
                  message: res.data.msg,
                  type: 'success'
              });
            }
          })
      }
    
    },
    linkTo(popUps) {
      if (popUps.advTarget == 2) {
        // 跳商品详情
        this.jumpDetail(popUps.goodsId, popUps.isRecentExpiration);
      }else {
        // 跳外链接
        window.location.href = popUps.url;
      }
      // this.$router.push(url)
    },
    jumpDetail(id, isRecentExpiration) {
      this.$router.push({
        name: "ProductDetail",
        query: {
          id: id,
          isRecentExpiration: isRecentExpiration,
        },
      });
    },
    frontListFun() {
      getHomeBanners().then((data) => {

        console.log("首页轮播图",data.data.data)
        //banner图+活动图
        this.$set(this.centerNavList, "bannerList", data.data.data.banners);
        this.popUps = data.data.data.popUps;
        console.log(" this.popUps this.popUps", this.popUps)
                if (this.popUps.img != undefined) {

                  if (localStorage.getItem("AlertFlag") == null) {
                    // 第一次进来
                    localStorage.setItem("AlertFlag", "true");
                    if(this.popUps.advTarget==4){
                        if(this.popUps.memberStoreReceiveCouponVO.length>0){
                            this.dialogVisible = true;


                            console.log('9999999999*************',this.popUps.memberStoreReceiveCouponVO)
                        }
                    }else{
                      this.dialogVisible = true;
                    }
                  
                  } else {
                    // 反之则隐藏
                    this.dialogVisible = false;
                  }
                }
      })
      getActivityOperation().then((data) => {
        if (JSON.stringify(data.data.data) == "{}") {
          this.hasValue = "false";
        } else {
              console.log("获取首页活动",data.data.data)
              //活动详情
              // this.$set(this.centerNavList, "activityDetail", data.data.data.activityDeatil);

              //公告
              // this.articlesList = data.data.data.articles;
              // this.hasValue = "true";
               
                // if (JSON.stringify(data.data.data.hotRecommend) !== "{}") {
                //   this.hotRecommend = data.data.data.hotRecommend;
                // }
            }

      })
      frontList().then((data) => {
        if (JSON.stringify(data.data.data) == "{}") {
          this.hasValue = "false";
        } else {
            //活动详情
            this.$set(this.centerNavList, "activityDetail", data.data.data.activityDeatil);
          // //公告
          this.articlesList = data.data.data.articles;
          //banner图+活动图
          // this.$set(this.centerNavList, "bannerList", data.data.data.banners);
          // this.$set(
          //   this.centerNavList,
          //   "activityDetail",
          //   data.data.data.activityDeatil
          // );
          console.log('this.centerNavList',this.centerNavList)
          this.hasValue = "true";
          // this.popUps = data.data.data.popUps;
          // if (this.popUps.img != undefined) {
          //   if (localStorage.getItem("AlertFlag") == null) {
          //     localStorage.setItem("AlertFlag", "true");
          //     if(this.popUps.advTarget==4){
          //         if(this.popUps.memberStoreReceiveCouponVO.length>0){
          //             this.dialogVisible = true;
          //         }
          //     }else{
          //       this.dialogVisible = true;
          //     }
             
          //   } else {
          //     this.dialogVisible = false;
          //   }
          // }
          if (JSON.stringify(data.data.data.hotRecommend) !== "{}") {
            this.hotRecommend = data.data.data.hotRecommend;
          }
        }
      });
    },
    // toDetail(){
    //   this.$notify.closeAll()
    //   // 点击查看资质过期详情
    //   this.AlertMsgBoxVisible=true
    // },
    CloseFun(){
      this.AlertMsgBoxVisible=false
      console.log('aathis.AlertMsgBoxVisible',this.AlertMsgBoxVisible)
    }
  },
  created() {
    // 根据规则名称查询
    PictureSpecs("productPic").then((res) => {
      localStorage.setItem("productPic", res.data.data);
    });
    getMemberStoreCertification().then(res=>{
      console.log('获取用户资质过期倒计时',res.data.data)
      if(res.data.data.length>0){
        this.MsgList=res.data.data
        if(localStorage.getItem("AlertMsgFlag") == null){
          localStorage.setItem("AlertMsgFlag", "true");
          this.AlertMsgBoxVisible=true
        }else{
          if(localStorage.getItem("AlertMsgFlag")){
            this.AlertMsgBoxVisible=false
          }
        }
        // const h = this.$createElement
        // const hrender = h('p', null, [
        //         h('div', [
        //           h('div',"您有资质即将过期，请联系质管及时更新"),
        //           ], null),
        //         h('button', {
        //           class: 'DetailBtn',
                 
        //         }, "去查看"),
        //         ])

        //         this.$notify.closeAll()
        //   this.$notify({
        //     title: '温馨提示',
        //     dangerouslyUseHTMLString: true,
        //     message: hrender,
        //     type: 'warning',
        //     position: 'bottom-right',
        //     duration: 0,
        //     // showClose: false,
        //     onClick: () => {
        //       this.toDetail()
        //     },
        //     offset: 10,
        //   })
        
        // this.$notify({
        //   title: "温馨提示",
        //   message: "您的资质即将过期，请联系质管及时更新",
        //   type: "warning",
        //   offset: 10,
        // });
      }else{
          this.AlertMsgBoxVisible=false
        }
    })
    MemberInfo().then((res) => {
      this.name = res.data.data.enterpriseName;
      this.memberLevelName = res.data.data.memberLevelName;
      localStorage.setItem("memberName", res.data.data.enterpriseName);
      localStorage.setItem("customName", res.data.data.customName);
      localStorage.setItem("customPhone", res.data.data.customPhone);

      localStorage.setItem('memberLevel',res.data.data.memberStoreLevel);

      localStorage.setItem('childErpNumberCount',res.data.data.childErpNumberCount);

      
      localStorage.setItem('erpNumber',res.data.data.erpNumber);
      this.memberName(res.data.data.enterpriseName);

      // 资质过期提示
      let arr = [];
      arr.push(res.data.data.jyxkzzt); //营业执照状态     0空 1过期 2正常 3即将过期
      arr.push(res.data.data.scjyxkzzt); //生产经营许可证状态 0空 1过期 2正常 3即将过期
      arr.push(res.data.data.zzjgdmzzt); //组织机构代码证状态 0空 1过期 2正常 3即将过期
      arr.push(res.data.data.zlbzxyqxzt); //质量保证协议期状态 0空 1过期 2正常 3即将过期
      arr.push(res.data.data.khcgyzt); //客户采购员状态     0空 1过期 2正常 3即将过期
      arr.push(res.data.data.khthrzt); //客户提货人状态     0空 1过期 2正常 3即将过期
      arr.push(res.data.data.spjyxkzzt); //食品经营许可证状态 0空 1过期 2正常 3即将过期
      arr.push(res.data.data.ylqxxkzt); //医疗器械许可证状态 0空 1过期 2正常 3即将过期


      console.log('arrarrarrarrarrarrarrarrarrarrarrarrarrarr',arr.indexOf(3))
      if (arr.indexOf(3) > -1) {
        //存在即将过期

       
        // this.$notify({
        //   title: "温馨提示",
        //   message: "您的资质即将过期，请联系质管及时更新",
        //   type: "warning",
        //   offset: 10,
        // });
      } else {
        //  已过期
        if (arr.indexOf(1) > -1) {
          this.$notify({
            title: "温馨提示",
            message: "您的资质已过期，请联系质管及时更新",
            type: "warning",
            offset: 10,
          });
        }
      }
    });
  },
  mounted: function () {
    this.$nextTick(function () {
      this.frontListFun(); //回退页面做刷新
    });
  },
};
</script>

<style lang="less" scoped>
// .bgBox{
//   // filter: grayscale(100%)
// }
// .DetailBtn{
//   float: right;
//   border-radius:4px;
//   padding:2px 6px;
//   font-size:15px;
//   border: 0px;
//   background: crimson;
//   color: #fff;
//   cursor: pointer;

// }
/deep/.el-dialog {
  display: flex;
  flex-direction: column;
  margin: 0 !important;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  max-height: calc(100% - 30px);
  max-width: calc(100% - 30px);
}
/deep/.AlertMsgBox {
  padding: 0;
  .el-dialog{
    border-radius:10px;
    overflow: hidden;
  }

  .el-dialog__header{
    padding: 0;
  }
  .el-dialog__body{
     background: #fff;
     padding: 0;
  }
  
  .AlertMsgBgBox{
    
    .imgBox{
      width: 100%;
      img{
        width: 100%;
        height: auto;
      }
    }
    .MsgBox{
      padding: 10px 20px;
      p{
        line-height: 26px;
        color: #333333;
        font-weight: bold;
        font-size: 15px;
      }
      .clearBox{
        clear: both;
      }
      ul{
        padding:20px 0 0px 0;
        li{
          color:#333;
          float: left;
          width: 50%;
          line-height: 24px;
          font-size: 14px;
          &:nth-child(odd){
            text-align: left;
          }
         &:nth-child(even){
          text-align: right;
          }
        }
      }
    }
   
    .closeBtn{
      width: 100%;
      padding-top: 20px;
      padding-bottom: 20px;
      img{
        display:block;
        width:36%;
          margin: 0 auto;
      }
    }
  }
 
}
/deep/.AlertBox {
  padding: 0;
.CouponsBox{
  display: flex;
    align-items: center;
    justify-content: space-between;
  width: 100%;
  position: relative;
  ul{
    position: absolute;
    top:42%;
    padding:0px;
    width: 100%;
      li{
        width:80%;
        margin: 0 auto;
        height:60px;
        padding:2px 0;
        
        .BgBox{
          cursor: pointer;
          width: 100%;
          height: 100%;
           background: url('../assets/AlertCouponBg.png') no-repeat;
          background-size: 100% 100%;
          .LeftBox{
              width:40%;
              height:50px;
              float: left;
              padding:2px 0;
              p{
                 font-size:28px;
                font-weight: bold;
                line-height:50px;
                text-align: center;
                color: #FF720A;
                sub{
                  font-size: 12px;
                  font-weight: bold;
                  // vertical-align:sub;
                }
                sup{
                  font-size: 12px;
                  font-weight: bold;
                  vertical-align:super;
                }
              }
            
            
          }
          .RightBtn{
            width:60%;
            height: 100%;
            float: right;
            position: relative;
            p{
              font-size:14px;
              font-weight: bold;
              white-space:nowrap;
              text-overflow:ellipsis;
              overflow:hidden;
              color:#ED6203;
              text-align: center;
              line-height: 30px;
            }
           .desMsg{
                font-size:13px;
                 text-align: center;
                  font-weight: bold;
                  span{
                    background: #facf7e ;
                    color: #ed6203;
                    padding:2px 8px;
                    border-radius:10px;
                  }
           }
            
          }
        }
      
      }
    }
  .GetBtn{
    position: absolute;
    bottom:0;
    padding:0px;
    width: 100%;
    img{
      display:block;
       width:80%;
        margin: 0 auto;
    }
  }
}
  .el-dialog {
    border-radius: 10px;
    background-color: transparent;
    box-shadow: none;
  }

  .el-dialog__header {
    padding: 0;

    .el-dialog__headerbtn {
      background: #fff;
      color: #908181;
      width: 30px;
      height: 30px;
      font-weight: bold;
      border-radius: 50%;
      right: -30px;
      top: -15px;

      .el-dialog__close {
        font-weight: bolder;
        color: #908181;
        line-height: 30px;
        font-size: 24px;
      }
    }
  }

  .el-dialog__body {
    padding: 0;
    border-radius: 10px;

    img {
      border-radius: 10px;
    }
  }
}

.el-container {
  position: relative;
}
.BannerBox{
  width:100%;
  position: relative;
  .centerNav {
    width:100%;
  }
  .bgBox{
    position: absolute;
    top: 0;
    z-index: 9;
  }
}
.home {
  width: 1200px;
  margin: 0 auto;
  padding: 0;
  overflow: hidden;
}

.navContent {
  // padding-top: 10px;
  padding-left: 10px;

  // .centerNav {
  //   width: 755px;
  //   float: left;
  // }

  .rightNav {
    width: 215px;
    background: #fff;
    float: left;
    margin-left: 10px;
    height:400px;
  }
}

.newGoods {
  padding-top: 30px;
  padding-bottom: 30px;
}

.el-main {
  width: 1200px;
  margin: 0 auto;
  padding: 0;
  overflow: inherit;
}

.ActivityAreaBox{
  .HeaderTit{
    color: #333333;
    font-size: 30px;
    font-weight: bold;
    line-height:70px;
  }
  .bottom {
    width: 100%;
    margin-bottom:20px;
    div{
      float: left;
      cursor: pointer;
      height: 100%;
      width: calc(50% - 9px);
      border-radius:15px;
      overflow: hidden;
      img{
        width: 100%;
        height: 100%
      }
    }
    div:nth-child(odd){
      margin-right:9px
    }
    div:nth-child(even){
      margin-left:9px
    }
  }
}
// @media screen and (max-width: 1280px) {
//   .home {
//     width: 990px;
//     margin: 0 auto;
//     padding: 0;
//   }

//   .el-main {
//     width: 990px;
//     margin: 0 auto;
//     padding: 0;
//     overflow: inherit;
//     .navContent {
//       .centerNav {
//         width: 545px;
//         float: left;
//       }
//     }
//   }
// }
</style>